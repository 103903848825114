<template>
  <div class="home">
    <div>
      <AdBanner :page='wpHome'/> 
    </div>
    <div id="slider">
      <Slider :develop='develop' :posts='slide' :Year="this.season" :config="config"/>
    </div>
    <div id='upComing'>
      <UpComing :develop='develop' :id="season" :config="config"/>
    </div>
    <div id="oom">
      <SmallOom
        :develop="develop"
        :oom="oom"
        :season="this.season"
        :home="homepage"
        :key="componentKey"
        :config="config"
        :seasonsadt="seasonsadt"
      />
    </div>
    <div id="feature">
      <Post :posts='posts' :develop="develop" :config="config"/> 
    </div>
    <div id="social" style="display:none">
      <b-container>
        <b-row>
          <b-col class='col-md-4 col-sm-12 mobSocial'>
            <h2>
              <b-row>
                <b-col class="col-md-2 instaLogo" >
                  <b-nav-item href="https://twitter.com/ADT_golf">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'twitter']"
                    />
                  </b-nav-item>
                </b-col>
                <b-col class="col-md-10 developGreen title socialTitle">
                  <span v-if="develop === config.VUE_APP_ID_ASIAN_TOUR">Asian Tour on Twitter</span>
                  <span v-else>ADT on Twitter</span>
                </b-col>
              </b-row>
            </h2>
            <Twitter :develop='develop' :config="config"/> 
          </b-col>
          <b-col class='col-md-8 col-sm-12 mobSocial'>
            <h2>
              <b-row>
                <b-col class="col-md-2 instaLogo">
                  <b-nav-item href="https://twitter.com/adt_golf">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'instagram']"
                    />
                  </b-nav-item>
                </b-col>
                <b-col class="col-md-10 developGreen title socialTitle">
                  <span v-if="develop === config.VUE_APP_ID_ASIAN_TOUR">Asian Tour on Instagram</span>
                  <span v-else>Asian Development Tour on Instagram</span>
                </b-col>
              </b-row>
            </h2>
            <InstaFeed :develop='develop' :config="config"/>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="sponsors">
      <Sponsors :sponsor='sponsor' :develop='develop' :config="config"/>
    </div>
  </div>
</template>

<script>
import AdBanner from "@/components/adBanner.vue";
import SmallOom from "@/components/smallOomADT.vue";
import Post from "@/components/posts.vue";
import Slider from "@/components/slider.vue";
import Twitter from "@/components/twitterFeed.vue";
import Sponsors from "@/components/sponsors.vue";
import InstaFeed from "@/components/instaFeed.vue";
import UpComing from "@/components/upcomingADT.vue";
export default {
  name: "ADThome",
  props:['develop', 'season', 'oom', 'config'],
  components: {
    AdBanner,
    Slider,
    UpComing,
    SmallOom,
    Post,
    Twitter,
    Sponsors,
    InstaFeed
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      title: [], //Page Title
      description: [], //Meta Description
      homepage: true,
      wpHome: this.config.VUE_APP_WP_ADT_API_URL + 'wp/v2/pages/572?_embed',
      slide: this.config.VUE_APP_WP_ADT_API_URL + 'wp/v2/posts?_embed',
      posts: this.config.VUE_APP_WP_ADT_API_URL + 'wp/v2/posts?_embed',
      sponsor: this.config.VUE_APP_WP_ADT_API_URL + 'wp/v2/',
      componentKey:0
    };
  },
  methods: {
    forceRerender(){
      this.componentKey +=1;
    }
  },
};
</script>
<style scoped>
.home {
  background-color: #f1f1f1;
}
#feature {
  padding-top: 2%;
  padding-bottom: 5%;
}
#social{
  padding-bottom:5%
}
::v-deep .nav-item {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .col-md-10 {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .fa-instagram{
  color: #cc4666;
}
.developGreen{
  color: #76c3bd;
}
#upComing{
      padding-top: 50px;
    padding-bottom: 50px;
}
  .instaLogo {
  max-width: 55px;
}
.socialTitle {
  font-size: 24px;
}
@media only screen and (max-width: 990px) {
  .mobSocial {
    display: block;
    width: 100%;
    min-width: 100%;
  }
  ::v-deep .card-img-top {
    width: 100%;
    height: auto;
    min-height: 183px;
    border-radius: 10px 10px 0 0 !important;
    max-height: 100%;
  }
  ::v-deep .homeFeaturedContent {
    max-width: 100%!important;
    margin-bottom: 2em!important;
  }
}
</style>
