<template>
  <div>
    <b-container v-if="loading === true">
      <!-- <h3
        class="titleMain"
        :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
      >
        Next Event
      </h3> -->
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col lg="4">
            <b-card-img
              v-if="FutureEventFilter[0].course_images === 'Y'"
              :src="
                DEFIMAGESAMS3 +
                'apga' +
                '/' +
                'courses' +
                '/' +
                id +
                '/' +
                FutureEventFilter[0].course_code +
                '/' +
                FutureEventFilter[0].course_image_file_1
              "
              alt="Image"
              class="rounded-0 ImageSec"
            ></b-card-img>
            <b-card-img
              v-else
              src="https://assets.asiantour.com/asian-tour/2022/06/sliderdummycourse.png"
              alt="Image"
              class="rounded-0 ImageSec"
            ></b-card-img>
          </b-col>
          <b-col lg="8">
            <b-card-body>
              <b-card-text>
                <template v-if="isMobile(true)">
                  <b-row>
                    <b-col cols="8">
                      <h2 class="title">{{ FutureEventFilter[0].full_name }}</h2>
                      <h2 class="dates">{{ FutureEventFilter[0].dic_dates }}</h2>
                      <h2 class="course">{{ FutureEventFilter[0].course }}</h2>
                      <h2 class="prize">
                        {{ FutureEventFilter[0].total_prize_fund }}
                      </h2>
                      <br />
                    </b-col>
                    <b-col cols="4" v-if="FutureEventFilter[0].sponsor_logo.length > 0">
                      <b-img
                        class="spon"
                        :src="FutureEventFilter[0].sponsor_logo"
                      ></b-img>
                    </b-col>
                    <b-col cols="4" v-else>
                      <b-img
                        class="spon"
                        src="https://assets.asiantour.com/asian-tour/2020/05/as.png"
                      ></b-img>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div v-show="reportInfo.reports.reports_entry.report_title === 'Tournament Entries'">
                        <b-button class="btn tourBtn btn-secondary" 
                        v-for="(reports, index) in reportFulldata" 
                        :key="index" 
                        v-show="reports.report_title === 'Tournament Entries'"
                        :to="{
                            name: 'reports?tour=adt',
                            query: {
                                url: reports.report_url,
                                id: id,
                                code: reportInfo.code,
                                title: reports.report_title,
                            },
                        }"
                        >
                          <span>{{reports.report_title}}</span>
                        </b-button>
                      </div>
                      <div v-hide="reportInfo.reports.reports_entry.report_title === 'Tournament Entries'">
                        <b-button
                          class="btn tourBtn btn-secondary"
                          v-if="filterEntry.length && filterEntry !== 'none'"
                          :to="{
                            name: 'reports?tour=adt',
                            query: {
                              url: filterEntry[0].report_url,
                              id: id,
                              code: reportInfo.code,
                              title: filterEntry[0].report_title,
                            },
                          }"
                          >Entry List</b-button
                        >
                        <b-button
                          class="btn tourBtn btn-secondary"
                          v-if="filterDraw.length && filterDraw !== 'none'"
                          role="presentation"
                          :to="{
                            name: 'reports?tour=adt',
                            query: {
                              url: filterDraw[0].report_url,
                              id: id,
                              code: reportInfo.code,
                              title: filterDraw[0].report_title,
                            },
                          }"
                          >Draw</b-button
                        >
                        <b-button
                          class="btn tourBtn btn-secondary"
                          v-if="filterScore.length && filterScore !== 'none'"
                          role="presentation"
                          :to="{
                            name: 'reports?tour=adt',
                            query: {
                              url: filterScore[0].report_url,
                              id: id,
                              code: reportInfo.code,
                              title: filterScore[0].report_title,
                            },
                          }"
                          >
                          <span v-if="reportInfo.live_scoring === 'Y'">Live Scoring</span>
                          <span v-else>Scoring</span>
                          </b-button
                        >
                        <template v-if="scoringUrl.length">
                          <b-button
                            class="btn tourBtn btn-secondary"
                            role="presentation"
                            :href="scoringUrl"
                            target="_blank"
                            >External Live Scoring</b-button
                          >
                        </template>
                        <b-button
                          class="btn tourBtn btn-secondary"
                          v-if="filterFinal.length && filterFinal !== 'none'"
                          role="presentation"
                          :to="{
                            name: 'reports?tour=adt',
                            query: {
                              url: filterFinal[0].report_url,
                              id: id,
                              code: reportInfo.code,
                              title: filterFinal[0].report_title,
                            },
                          }"
                          >Results</b-button
                        >
                      </div>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <b-row>
                    <b-col cols="8">
                      <h2 class="title">{{ FutureEventFilter[0].full_name }}</h2>
                      <h2 class="dates">{{ FutureEventFilter[0].dic_dates }}</h2>
                      <h2 class="course">{{ FutureEventFilter[0].course }}</h2>
                      <h2 class="prize">
                        {{ FutureEventFilter[0].total_prize_fund }}
                      </h2>
                      <br />
                      <b-row>
                        <b-col cols="12">
                          <div v-show="reportInfo.reports.reports_entry.report_title === 'Tournament Entries'">
                            <b-button class="btn tourBtn btn-secondary" 
                            v-for="(reports, index) in reportFulldata" 
                            :key="index" 
                            v-show="reports.report_title === 'Tournament Entries'"
                            :to="{
                                name: 'reports?tour=adt',
                                query: {
                                    url: reports.report_url,
                                    id: id,
                                    code: reportInfo.code,
                                    title: reports.report_title,
                                },
                            }"
                            >
                              <span>{{reports.report_title}}</span>
                            </b-button>
                          </div>
                          <div v-hide="reportInfo.reports.reports_entry.report_title === 'Tournament Entries'">
                            <b-button
                              class="btn tourBtn btn-secondary"
                              v-if="filterEntry.length && filterEntry !== 'none'"
                              :to="{
                                name: 'reports?tour=adt',
                                query: {
                                  url: filterEntry[0].report_url,
                                  id: id,
                                  code: reportInfo.code,
                                  title: filterEntry[0].report_title,
                                },
                              }"
                              >Entry List</b-button
                            >
                            <b-button
                              class="btn tourBtn btn-secondary"
                              v-if="filterDraw.length && filterDraw !== 'none'"
                              role="presentation"
                              :to="{
                                name: 'reports?tour=adt',
                                query: {
                                  url: filterDraw[0].report_url,
                                  id: id,
                                  code: reportInfo.code,
                                  title: filterDraw[0].report_title,
                                },
                              }"
                              >Draw</b-button
                            >
                            <b-button
                              class="btn tourBtn btn-secondary"
                              v-if="filterScore.length && filterScore !== 'none'"
                              role="presentation"
                              :to="{
                                name: 'reports?tour=adt',
                                query: {
                                  url: filterScore[0].report_url,
                                  id: id,
                                  code: reportInfo.code,
                                  title: filterScore[0].report_title,
                                },
                              }"
                              >
                              <span v-if="reportInfo.live_scoring === 'Y'">Live Scoring</span>
                              <span v-else>Scoring</span>
                              </b-button
                            >
                            <template v-if="scoringUrl.length">
                              <b-button
                                class="btn tourBtn btn-secondary"
                                role="presentation"
                                :href="scoringUrl"
                                target="_blank"
                                >External Live Scoring</b-button
                              >
                            </template>
                            <b-button
                              class="btn tourBtn btn-secondary"
                              v-if="filterFinal.length && filterFinal !== 'none'"
                              role="presentation"
                              :to="{
                                name: 'reports?tour=adt',
                                query: {
                                  url: filterFinal[0].report_url,
                                  id: id,
                                  code: reportInfo.code,
                                  title: filterFinal[0].report_title,
                                },
                              }"
                              >Results</b-button
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="4" v-if="FutureEventFilter[0].sponsor_logo.length > 0">
                      <b-img
                        class="spon"
                        :src="FutureEventFilter[0].sponsor_logo"
                      ></b-img>
                    </b-col>
                    <b-col cols="4" v-else>
                      <b-img
                        class="spon"
                        src="https://assets.asiantour.com/asian-tour/2020/05/as.png"
                      ></b-img>
                    </b-col>
                  </b-row>
                </template>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
			<div v-show="livemessage == 'Y' || playSuspended == 'Y'" class="livemessage" :class="{colorMess : playSuspended == 'Y'}">
				<div>
					<h3 v-show="playSuspended == 'Y'" class="livemestext">Play Suspended</h3>
					<h3 v-show="livemessage == 'Y' && livemessageText.length > 0" class="livemestext">{{livemessageText}}</h3>
				</div>
			</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "upComing",
  props: ["config", "develop"],
  data() {
    return {
      reportFulldata: [],
      reportInfo: [],
      tours: [],
      tm_params: [],
      id: "",
      loading: ''
    };
  },
  methods: {
    isMobile() {
      if( screen.width <= 500 ) {
        return true;
      }
      else {
        return false;
      }
    },
    currentReport: function() {
      if (Array.isArray(this.FutureEventFilter[0].reports.reports_entry))
        return this.FutureEventFilter[0].reports.reports_entry[
          this.reports.length - 1
        ].report_url;
      else return this.FutureEventFilter[0].reports.reports_entry.report_url;
    },
    currentTitle: function() {
      if (Array.isArray(this.FutureEventFilter[0].reports.reports_entry))
        return this.FutureEventFilter[0].reports.reports_entry[
          this.reports.length - 1
        ].report_title;
      else return this.FutureEventFilter[0].reports.reports_entry.report_title;
    },

  },
  computed: {
    rPlayed: function() {
      return this.tm_params.rounds_played;
    },
    FutureEventFilter: function() {
      var upC = this.tours.filter((data) => data.code === this.tm_params.code);
      return upC;
    },
    filterEntry: function(entry) {
      if (Array.isArray(this.reportInfo.reports.reports_entry))
        entry = this.reportInfo.reports.reports_entry.filter(
          (reportInfo) => !reportInfo.report_title.indexOf("")
        );
      else if (this.reportInfo.reports.length) 
      entry = this.reportInfo.reports.reports_entry.report_title;
      else entry = 'none'
      return entry;
    },
    filterDraw: function(draw) {
      if (Array.isArray(this.tm_params.reports.reports_entry))
      if (this.rPlayed === '0') {
        draw = this.tm_params.reports.reports_entry.filter(
          (tm_params) =>
            !tm_params.report_title.indexOf("Round " + "1" + " Draw")
        );
      } else {
        draw = this.tm_params.reports.reports_entry.filter(
          (tm_params) =>
            !tm_params.report_title.indexOf("Round " + this.rPlayed + " Draw")
        );
      }
      else draw = "none";
      return draw;
    },
    filterScore: function(score) {
      if (Array.isArray(this.tm_params.reports.reports_entry))
      if (this.rPlayed === '0' || this.rPlayed === '1') {
        score = this.tm_params.reports.reports_entry.filter(
          (tm_params) =>
            !tm_params.report_title.indexOf(
              "Round " + "1" + " Scores Only"
            )
        );
      } else {
        score = this.tm_params.reports.reports_entry.filter(
          (tm_params) =>
            !tm_params.report_title.indexOf(
              "Round " + this.rPlayed + " Scoreboard"
            )
        ); 
      }
      else score = "none";
      return score;
    },
    filterFinal: function(final) {
      if (Array.isArray(this.reportInfo.reports.reports_entry))
        final = this.reportInfo.reports.reports_entry.filter(
          (reportInfo) => !reportInfo.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    },
  },
  mounted() {
    setTimeout(() => {
      axios //add API Call
        .get(
          process.env.VUE_APP_TIC_BASE +
            'adt' +
            "/tmticx?randomadd=" +
            new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => {
          (this.tm_params = response.data),
          (this.id = response.data.tm_params.season_code);
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3
          this.loading =true;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              'adt' +
              "/" +
              this.id +
              "/tmticx?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.tours = response.data.tournaments.tournaments_entry;
          this.reportInfo = response.data;
          this.reportFulldata = response.data.reports;
          this.scoringUrl = response.data.scoring_url;
          this.livemessage = response.data.live_message;
          this.livemessageText = response.data.live_scoring_mess;
          this.playSuspended = response.data.play_suspended;
          this.loading =true;
        });
    }, 1000);
  },
};
</script>

<style scoped>
.colorMess {
  background: red!important;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;
}
.livemessage {
  background: #76c3bd;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 99;
}
.titleMain {
  color: #0b3f7e;
  padding-bottom: 20px;
}
.spon {
  max-width: 120px;
  margin-top: 20px;
  margin-bottom: 1.3em;
  margin-top: 1.5em;
}
.title {
  color: #76c3bd;
  text-align: left;
  padding-left: 15px;
  font-size: 16px !important;
  font-weight: bold;
}
.dates {
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
}
.course {
  font-size: 15px;
  color: #76c3bd;
  text-align: left;
  padding-left: 15px;
}
.prize {
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.tourBtn{
  font-size: 16px;
  color: #fff!important;
  background: #76c3bd;
  margin-bottom: 10px;
  display: block;
  border-radius: 10px;
  width: 100%;
  border-color: #76c3bd;
  
}
.tourBtn:hover{
  background-color: #0a3f7f;
  border-color: #0a3f7f;
  width: 100%;
}
.ImageSec {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  ::v-deep li.nav-item.tabsReport {
    display: block;
    align-items: center;
    padding-top: 0px!important;
    width: 100%!important;
  }
  .title {
    color: #76c3bd;
    text-align: left;
    padding-left: 0px;
    font-size: 16px !important;
    font-weight: bold;
  }
  .dates {
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-align: left;
    padding-left: 0px;
  }
  .course {
    font-size: 15px;
    color: #76c3bd;
    text-align: left;
    padding-left: 0px;
  }
  .prize {
    font-size: 15px;
    color: #666666;
    font-weight: 400;
    text-align: left;
    padding-left: 0px;
  }
  .spon {
    max-width: 100px;
    margin-top: 20px;
    margin-bottom: 1.3em;
    margin-top: 1.5em;
  }
}
</style>