 <template>
  <div>
    <a v-if="this.$route.name === 'home'" class="twitter-timeline" data-height="650" :href="'https://twitter.com/' + config.VUE_APP_ASIAN_TOUR_TWITTER_NAME + '?ref_src=twsrc%5Etfw'">Tweets by asiantourgolf</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    <a v-if="this.$route.name === config.VUE_APP_ID_ADT" class="twitter-timeline" data-height="650" :href="'https://twitter.com/' + config.VUE_APP_ADT_TWITTER_NAME + '?ref_src=twsrc%5Etfw'">Tweets by ADT_golf</a> <script type="application/javascript"  async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </div>
</template>

<script>
export default {
  name: "twitterFeed",
  props:['develop', "config"]
};
</script>