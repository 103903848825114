<template>
  <div>
    <b-row v-if="develop === 'apga'">
      <b-col
        lg="3"
        md='6'
        v-for="(insta, index) in instaAT.data.slice(0, 12)"
        :key="index"
      >
        <a :href="insta.permalink" target="_blank">
          <template v-if="insta.media_type === 'CAROUSEL_ALBUM'">
            <div class="instaInner-pic" :style="'background-image:url('+insta.media_url +');'">
              <font-awesome-icon
                id="fb"
                class="playCircle"
                :icon="['fa', 'play-circle']"
              />
            </div>      
          </template>
          <template v-else-if="insta.media_type === 'VIDEO'">
            <div class="instaInner-pic" :style="'background-image:url('+insta.thumbnail_url +');'">
              <font-awesome-icon
                id="fb"
                class="playCircle"
                :icon="['fa', 'play-circle']"
              />
            </div>      
          </template>
          <template v-else>
            <div class="outer" :style="'background-image:url('+ insta.media_url +');'"> 
            </div>
          </template>
        </a>
      </b-col>
    </b-row>
    
    <b-row v-if="develop === 'adt'">
      <b-col
        lg="3"
        md='6'
        v-for="(insta, index) in instaADT.data.slice(0, 12)"
        :key="index"
      >
        <a :href="insta.permalink" target="_blank">
          <div class="outer">
            <b-img
              v-if="
                insta.media_type === 'CAROUSEL_ALBUM' ||
                  insta.media_type === 'IMAGE'
              "
              class="instaPic"
              :src="insta.media_url"
            ></b-img>
            <b-img v-else class="instaPic" :src="insta.thumbnail_url"></b-img>
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "instafeed",
  props:['develop', 'config'],
  data() {
    return {
      instaAT: [],
      instaADT: [],
    };
  },
  mounted() {
    const apiAT = process.env.VUE_APP_INSTAGRAM_URL + process.env.VUE_APP_INSTA_ASIAN_TOUR_KEY;
    const apiADT = process.env.VUE_APP_INSTAGRAM_URL + process.env.VUE_APP_INSTA_ADT_KEY;
    axios
      .get(apiAT)
      .then((response) => {
        this.instaAT = response.data;
        return axios.get(apiADT);
      })
      .then((response) => {
        this.instaADT = response.data;
      });
  },
};
</script>

<style scoped>
.instaInner-pic {
  display: block;
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}
.instaPic {
  width: 100%;
}
.outer {
  height: 200px;
  width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (max-width: 768px) {
  .outer {
      height: 390px;
      width: 100%;
      margin: 10px 0;
  }
}
@media only screen and (max-width: 500px) {
  .instaInner-pic {
    display: block;
    height: 390px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 480px) {
  .outer {
      height: 390px;
      width: 100%;
      margin: 10px 0;
  }
}
</style>